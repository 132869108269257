<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Prelab Quiz - Determining Mole Ratios</h2>

      <p class="mb-2">
        a) What approaches will be used to determine the densities of the solid? Choose all that
        apply:
      </p>

      <p v-for="option in options1" :key="'pt-1-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input1" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-n3">b) How many plastic thermos (calorimeters) are needed in this experiment?</p>

      <v-radio-group v-model="inputs.input2" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options2"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">c) What solution goes into the thermos first?</p>

      <v-radio-group v-model="inputs.input3" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options3"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">d) How many trials should you do?</p>

      <v-radio-group v-model="inputs.input4" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options4"
          :key="'pt-4-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">e) What property is being recorded in this experiment?</p>

      <v-radio-group v-model="inputs.input5" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options5"
          :key="'pt-5-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'HawkeyePrelabQuiz4',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      options1: [
        {text: '$\\text{Shorts and Sandals}$', value: 'a'},
        {text: '$\\text{Gloves}$', value: 'b'},
        {text: '$\\text{Goggles}$', value: 'c'},
        {text: '$\\text{Hard hat}$', value: 'd'},
        {text: '$\\text{None}$', value: 'e'},
      ],
      options2: [
        {text: '$\\text{0}$', value: '0'},
        {text: '$\\text{1}$', value: '1'},
        {text: '$\\text{2}$', value: '2'},
        {text: '$\\text{3}$', value: '3'},
        {text: '$\\text{4}$', value: '4'},
      ],
      options3: [
        {text: '$\\text{Unidentified Acid}$', value: 'a'},
        {text: '$\\text{NaOH}$', value: 'b'},
        {text: '$\\text{Water}$', value: 'c'},
        {text: '$\\text{None of the above}$', value: 'd'},
      ],
      options4: [
        {text: '$\\text{1}$', value: '1'},
        {text: '$\\text{2}$', value: '2'},
        {text: '$\\text{3}$', value: '3'},
        {text: '$\\text{4}$', value: '4'},
        {text: '$\\text{5}$', value: '5'},
      ],
      options5: [
        {text: '$\\text{Change in temperature}$', value: 'a'},
        {text: '$\\text{Change in pH}$', value: 'b'},
        {text: '$\\text{Change in smell}$', value: 'c'},
        {text: '$\\text{Change in volume}$', value: 'd'},
        {text: '$\\text{Change in color}$', value: 'e'},
      ],
    };
  },
};
</script>
<style scoped>
.v-input--checkbox {
  display: inline-block !important;
}
</style>
